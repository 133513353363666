const contactFormURL = 'https://rxeoui17w8.execute-api.eu-central-1.amazonaws.com/default/personalPageSendEmail'

const contactFormSubmitDOM = document.getElementById('contact-form-submit')
const contactFormClearDOM = document.getElementById('contact-form-clear')
const nameField = document.getElementById('name')
const contactField = document.getElementById('contact')
const messageField = document.getElementById('message')
const messageSendingInProgressDOM = document.getElementById('message-sent-in-progress')
const messageSentSuccessDOM = document.getElementById('message-sent-success')
const messageSentFailureDOM = document.getElementById('message-sent-failure')

contactFormSubmitDOM.addEventListener('click', (ev) => {
  ev.preventDefault();
  messageSendingInProgressDOM.classList.remove('hidden')
  const formValues = {
    name: nameField.value,
    contact: contactField.value,
    message: messageField.value,
  }
  fetch(contactFormURL, {
    method: 'POST',
    body: JSON.stringify(formValues)
  }).then(() => {
    messageSendingInProgressDOM.classList.add('hidden')
    messageSentSuccessDOM.classList.remove('hidden')
    contactFormSubmitDOM.disabled = true
  }).catch(() => {
    messageSentFailureDOM.classList.remove('hidden')
  })
})

const cleanForm = () => {
  nameField.value = ''
  contactField.value = ''
  messageField.value = ''
  messageSendingInProgressDOM.classList.add('hidden')
  messageSentSuccessDOM.classList.add('hidden')
  messageSentFailureDOM.classList.add('hidden')
  contactFormSubmitDOM.disabled = false
}

contactFormClearDOM.addEventListener('click', (ev) => {
  ev.preventDefault();
  cleanForm()
})
